<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    OPERASIONAL / FORMULIR PENGAJUAN / <span style="font-weight: 600;">RIWAYAT PEJABAT</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <ApprovalNotification></ApprovalNotification>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  HI,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          RIWAYAT PEJABAT
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DATA RIWAYAT PEJABAT
        </span>
      </div>
    </div>
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="CARI FORM PENGAJUAN"
            solo
            hide-details
            class="mb-12 mr-4"
            style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          ></v-text-field>
          <v-btn
            color="primary"
            dark
            class="mb-12"
            @click="generateReportAll()"
          >
            Cetak
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="headers_history_officials"
          :items="data_history_officials"
          :item-key="official_id"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
          :item-class="row_bg_color"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.project_amount`]="{ item }">
            <td>Rp {{ formatNumber(item.project_amount) }}</td>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <td>Rp {{ formatNumber(item.amount) }}</td>
          </template>
        </v-data-table>
      </template>
      <!-- PDF ALL Session -->
      <template>
        <div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :pdf-quality="2"
            :manual-pagination="false"
            :html-to-pdf-options="htmlToPdfAllOptions"
            pdf-content-width="100%"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2PdfAll"
          >
            <section slot="pdf-content">
              <!-- PDF Content Here -->
              <section class="pdf-item">
                <div align="left">
                  <p
                    style="
                          font-family: 'Verdana';
                          font-style: normal;
                          font-size: 16px;
                        "
                  >
                    RIWAYAT PEJABAT
                  </p>
                </div>
                <br />
                <div align="left">
                  <table style="border-collapse: collapse; border-spacing: 0;">
                    <tr>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        PROYEK:
                      </td>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        {{ data_history_officials_print.project_name }}
                      </td>
                    </tr>
                  </table>

                  <table style="border-collapse: collapse; border-spacing: 0;">
                    <tr>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        NOMINAL PROYEK:
                      </td>
                      <td
                        style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                      >
                        RP {{ formatNumber(data_history_officials_print.project_amount) }}
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                <div align="center">
                  <table border="1" width="100%">
                    <thead>
                      <tr align="center">
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          width="5%"
                        >
                          NO
                        </th>
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          NAMA
                        </th>
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          JABATAN
                        </th>
                        <th
                          scope="col"
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          NOMINAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in data_history_officials"
                        :key="data.official_id"
                        align="center"
                      >
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ index + 1 }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ data.official_name }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          {{ data.position_name }}
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                        >
                          RP {{ formatNumber(data.amount) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <div class="html2pdf__page-break" />
            </section>
          </vue-html2pdf>
        </div>
      </template>
      <!-- END PDF ALL Session -->
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}

.position-relative:hover .position-absolute {
  top: auto;
}
</style>

<style>
.bg-green {
  background-color: rgba(0, 255, 0, 0.2);
}

.bg-red {
  background-color: rgba(255, 0, 0, 0.2);
}

.bg-yellow {
  background-color: rgba(255, 255, 0, 0.2);
}

.bg-blue {
  background-color: rgba(2, 118, 255, 0.2);
}

.bg-orange {
  background-color: rgba(255, 165, 0, 0.2);
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
import localStorage from "@/core/services/store/localStorage";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import VueHtml2pdf from "vue-html2pdf";
import Swal from "sweetalert2";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";
import axios from 'axios';

export default {
  name: "submission-form",

  components: {
    ApprovalNotification,
    VueHtml2pdf
  },

  data: () => ({
    search: "",
    selected: [],
    disabledCount: 0,
    searchOfficials: "",
    searchHistoryOfficials: "",
    dialog: false,
    dialogSubmissionInfo: false,
    dialogCreateOfficials: false,
    dialogDelete: false,
    dialogDeleteOfficials: false,
    dialogShowOfficialsTable: false,
    dialogShowHistoryOfficialsTable: false,
    dialogMoneyStatus: false,
    create_data_submission_form: [],
    update_data_submission_form: [],
    update_data_submission_form_amount: [],
    delete_data_submission_form: [],
    create_data_officials: [],
    create_data_voucher: [],
    update_data_officials: [],
    delete_data_officials: [],
    data_submission_form: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_officials: [],
    data_history_officials: [],
    data_workgroup_combobox: [],
    data_organizational_units_combobox: [],
    data_project_combobox: [],
    data_work_units_combobox: [],
    data_sub_work_units_combobox: [],
    data_part_of_sub_work_units_combobox: [],
    data_positions_combobox: [],
    data_token: [],
    data_sub_organizational_unit_combobox: [],
    data_payment_status_combobox: [
      {
        payment_status_id: "100%",
        payment_status_name: "100%"
      },
      {
        payment_status_id: "80%",
        payment_status_name: "80%"
      },
      {
        payment_status_id: "20%",
        payment_status_name: "20%"
      },
      {
        payment_status_id: "Lain-lain",
        payment_status_name: "LAIN-LAIN"
      }
    ],
    data_money_status_combobox: [
      {
        money_status_id: "S",
        money_status_name: "PENGAJUAN"
      },
      {
        money_status_id: "HO",
        money_status_name: "UANG DISERAHKAN"
      },
      {
        money_status_id: "R",
        money_status_name: "UANG DIKEMBALIKAN"
      }
    ],
    data_submission_form_date: {
      date_submission: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    data_history_officials_print: {
      project_id: "",
      project_name: "",
      project_amount: ""
    },
    add_submission_form: {
      workgroup_id: "",
      organizational_unit_id: "",
      work_unit_id: "",
      sub_work_unit_id: "",
      sub_work_unit_name: "",
      part_of_sub_work_unit_id: "",
      part_of_sub_work_unit_name: "",
      project_id: "",
      date_submission: new Date().toISOString().substr(0, 10),
      submission_desc: "",
      submission_type: "",
      submission_permission: "",
      transaction_type: "",
      amount: 0,
      payment_status: "",
      money_status: " ",
      sub_organizational_unit: "",
      submission_info: ""
    },
    add_submission_form_payment_status: {
      payment_status: ""
    },
    add_officials: {
      official_id: "",
      submission_number: "",
      organizational_unit_id: "",
      work_unit_id: "",
      sub_work_unit_id: "",
      sub_work_unit_name: "",
      part_of_sub_work_unit_id: "",
      part_of_sub_work_unit_name: "",
      position_id: "",
      position_name: "",
      official_name: "",
      official_rank: "",
      amount_submission: 0,
      amount_checking: 0,
      amount_approval: 0
    },
    add_submission_form_not_null: {
      date_submission_form: false,
      date_submission: formatDate()
    },
    search_history: {
      amount_range_from: 0,
      amount_range_to: 0
    },
    submitted: false,
    headers: [],
    headers_officials: [],
    headers_history_officials: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    editedItemOfficials: {},
    editedItemPaymentStatus: {},
    editedSubmissionInfo: {},
    deletedItem: {},
    deletedItemOfficials: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    htmlToPdfAllOptions: {
      margin: 0.1,
      filename: `RIWAYA PEJABAT.pdf`,
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait"
      }
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    date_submission: "",
    date: "",
    user_role: "",
    seen: false,
    seenHistory: false,
    seenButtonPrint: false,
    submissionNumber: "",
    organizationalUnitId: "",
    workUnitId: "",
    submissionAmount: "",
    moneyStatus: 0,
    cashSubmissionStatus: 0,
    checkingStatus: "",
    approvalStatus: "",
    dateSubmission: "",
    workgroupId: "",
    projectId: "",
    submissionDesc: "",
    submissionAmountToVoucher: "",
    createdBy: "",
    user_id: "",
    user_fullname: "",
    email: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    paymentStatus: "",
    loadingTable: true
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      let result = "";

      if (this.editedIndex === -1) {
        result = "BUAT FORM PENGAJUAN";
      } else if (this.editedIndex === -2) {
        result = "BUAT PEJABAT";
      } else if (this.editedIndex === -3) {
        result = "UBAH PEJABAT";
      } else if (this.editedIndex === -4) {
        result = "BERIKAN INFORMASI";
      } else {
        result = "UBAH FORM PENGAJUAN";
      }

      return result;
    },

    formInput() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form;
      } else if (this.editedIndex === -2) {
        result = this.add_officials;
      } else if (this.editedIndex === -3) {
        result = this.editedItemOfficials;
      } else if (this.editedIndex === -4) {
        result = this.editedSubmissionInfo;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formInputPaymentStatus() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form_payment_status;
      } else {
        result = this.editedItemPaymentStatus;
      }

      return result;
    },

    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_submission_form_not_null;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formSubmit() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.createDataSubmissionForm;
      } else if (this.editedIndex === -2) {
        result = this.createDataOfficials;
      } else if (this.editedIndex === -3) {
        result = this.updateDataOfficials;
      } else if (this.editedIndex === -4) {
        result = this.updateDataSubmissionFormInfo;
      } else {
        result = this.updateDataSubmissionForm;
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.date_submission);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    },

    selectAllSubUnitParts() {
      return (
        this.formInput.part_of_sub_work_unit_id.length ===
        this.data_part_of_sub_work_units_combobox.length
      );
    },

    selectSomePartsOfTheSubUnit() {
      return (
        this.formInput.part_of_sub_work_unit_id.length > 0 &&
        !this.selectAllSubUnitParts
      );
    },

    icon() {
      if (this.selectAllSubUnitParts) return "mdi-close-box";
      if (this.selectSomePartsOfTheSubUnit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogCreateOfficials(val) {
      val || this.closeOfficials();
    },

    dialogSubmissionInfo(val) {
      val || this.closeSubmissionInfo();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    dialogDeleteOfficials(val) {
      val || this.closeDeleteOfficials();
    },

    dialogMoneyStatus(val) {
      val || this.closeMoneyStatus();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    date_submission() {
      this.formInputNotClear.date_submission = this.formatDate(
        this.date_submission
      );
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    }
  },

  created() {
    const self = this;
    this.data_officials.map(item => {
      if (item.paid_status === 0 ? false : true) self.disabledCount += 1;
    });

    this.initHedersHistoryOfficials();
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Form Pengajuan" }]);
  },

  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    row_bg_color(item) {
      if (item.money_status == "HO") {
        return "bg-green";
      } else if (item.money_status == "R") {
        return "bg-red";
      } else if (item.money_status == "S") {
        return "bg-yellow";
      } else if (item.money_status == "C") {
        return "bg-blue";
      } else if (item.money_status == "A") {
        return "bg-orange";
      }
    },

    selectAllToggle(props) {
      if (
        this.selected.length !=
        this.data_officials.length - this.disabledCount
      ) {
        this.selected = [];
        const self = this;
        props.items.forEach(item => {
          if (!(item.paid_status === 0) ? false : true) {
            self.selected.push(item);
          }
        });
      } else this.selected = [];
    },

    enterSelect(values) {
      let data_officials = values.map(function(value) {
        return value.official_id;
      });

      this.add_officials.official_id = data_officials;
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectAllSubUnitParts) {
          this.formInput.part_of_sub_work_unit_id = [];
        } else {
          this.formInput.part_of_sub_work_unit_id = this.data_part_of_sub_work_units_combobox.slice();
        }
      });
    },

    getHeadersHistoryOfficials() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "official_id",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "SUMBER PENGAJUAN",
          sortable: true,
          value: "submission_permission"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name"
        },
        {
          text: "NOMINAL PROYEK",
          sortable: true,
          value: "project_amount"
        },
        {
          text: "JABATAN",
          sortable: true,
          value: "position_name"
        },
        {
          text: "NAMA",
          sortable: true,
          value: "official_name"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount"
        }
      ];
    },

    loadDate: function() {
      this.date_submission = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date_submission = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_submission_form.save(item);
      this.data_submission_form_date.date_submission = item;
    },

    onChangeSearchHistoryAmountFrom(item) {
      this.search_history.amount_range_from = item;
    },

    onChangeSearchHistoryAmountTo(item) {
      this.search_history.amount_range_to = item;
    },

    dataHistoryOfficials() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_HISTORY_OFFICIALS"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Officials",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_history_officials = response.data;
            this.seenButtonPrint = true;
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([
        //await this.dataEscrowPaymentAccepted(),
        //await this.dataBankGuaranteeNotification(),
        //await this.dataSubmissionFormNotification(),
        await this.dataHistoryOfficials()
      ]).then(function(results) {
        results;
      });
    },

    officialsSelect(item) {
      this.submissionNumber = item.submission_number;
      this.organizationalUnitId = item.organizational_unit_id;
      this.workUnitId = item.work_unit_id;
      this.data_history_officials_print.project_name = item.project_name;
      this.data_history_officials_print.project_amount = item.project_amount;
      this.submissionAmount = this.amount;
      this.cashSubmissionStatus = item.cash_submission_status;
      this.checkingStatus = item.checking_status;
      this.approvalStatus = item.approval_status;

      this.dateSubmission = item.date_submission;
      this.workgroupId = item.workgroup_id;
      this.projectId = item.project_id;
      this.submissionDesc = item.submission_desc;
      this.submissionAmountToVoucher = item.amount;
      this.createdBy = item.created_by;

      this.dataOfficials();
      this.dialogShowOfficialsTable = true;
    },

    showHistoryOfficials(item) {
      this.seenHistory = item;
      this.seenButtonPrint = false;
      this.dialogShowOfficialsTable = item;

      if (!item) {
        this.search_history.amount_range_from = 0;
        this.search_history.amount_range_to = 0;
      }
    },

    createOfficials() {
      this.editedIndex = -2;
      this.dialogCreateOfficials = true;
    },

    editItem(item) {
      this.editedIndex = this.data_submission_form.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.loadCombobox();
      this.titleFromSubOrganizationalUnit(item.sub_organizational_unit);
      this.editedItemPaymentStatus = Object.assign({}, item);
      if (
        this.editedItem.payment_status != "100%" &&
        this.editedItem.payment_status != "80%" &&
        this.editedItem.payment_status != "20%"
      ) {
        this.editedItem.payment_status = "Lain-lain";
        this.seen = this.editedItem.payment_status;
      }
      this.dialog = true;
    },

    editItemOfficials(item) {
      this.editedIndex = -3;
      this.editedItemOfficials = Object.assign({}, item);
      this.loadComboboxOfficials();
      this.dialogCreateOfficials = true;
      this.amountApprovalOfficials = item.amount_approval;
    },

    editItemSubmissionInfo(item) {
      this.editedIndex = -4;
      this.editedSubmissionInfo = Object.assign({}, item);
      this.dialogSubmissionInfo = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_submission_form.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemOfficials(item) {
      this.deletedIndex = this.data_officials.indexOf(item);
      this.deletedItemOfficials = Object.assign({}, item);
      this.dialogDeleteOfficials = true;
    },

    deleteItemConfirm() {
      this.data_submission_form.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    deleteItemConfirmOfficials() {
      this.data_officials.splice(this.deletedIndex, 1);
      this.closeDeleteOfficials();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_submission_form.workgroup_id = "";
        this.add_submission_form.organizational_unit_id = "";
        this.add_submission_form.sub_organizational_unit = "";
        this.add_submission_form.work_unit_id = "";
        this.add_submission_form.sub_work_unit_id = "";
        this.add_submission_form.sub_work_unit_name = "";
        this.add_submission_form.part_of_sub_work_unit_id = "";
        this.add_submission_form.part_of_sub_work_unit_name = "";
        this.add_submission_form.project_id = "";
        this.add_submission_form.submission_desc = "";
        this.add_submission_form.amount = 0;
        this.add_submission_form.payment_status = "";
        this.add_submission_form_payment_status.payment_status = "";
        this.seen = this.formInput.payment_status;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItemPaymentStatus = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeMoneyStatus() {
      this.dialogMoneyStatus = false;
      this.add_submission_form.money_status = "";
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    closeOfficials() {
      this.dialogCreateOfficials = false;
      this.$nextTick(() => {
        this.add_officials.position_id = "";
        this.add_officials.official_name = "";
        this.add_officials.official_rank = "";
        this.add_officials.amount_approval = 0;
        this.editedItemOfficials = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeSubmissionInfo() {
      this.dialogSubmissionInfo = false;
      this.editedSubmissionInfo = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    closeDeleteOfficials() {
      this.dialogDeleteOfficials = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_submission_form") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.add_submission_form
          );
        } else {
          this.data_submission_form.push(this.add_submission_form);
        }

        this.close();
      } else if (formInput == "edit_submission_form") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_submission_form.push(this.editedItem);
        }

        this.close();
      } else if (formInput == "edit_submission_form_info") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_submission_form.push(this.editedItem);
        }

        this.closeSubmissionInfo();
      } else if (formInput == "add_officials") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_officials[this.editedIndex],
            this.add_officials
          );
        } else {
          this.data_officials.push(this.add_officials);
        }

        this.closeOfficials();
      } else if (formInput == "edit_officials") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_officials[this.editedIndex], this.editedItem);
        } else {
          this.data_officials.push(this.editedItem);
        }

        this.closeOfficials();
      } else if (formInput == "edit_money_status") {
        if (this.editedIndex > -1) {
          Object.assign(
            this.data_submission_form[this.editedIndex],
            this.editedItem
          );
        } else {
          this.data_submission_form.push(this.editedItem);
        }

        this.closeMoneyStatus();
      }
    },

    generateReportAll() {
      this.data_history_officials;
      this.$refs.html2PdfAll.generatePdf();
    },

    initHedersHistoryOfficials() {
      this.headers_history_officials = this.getHeadersHistoryOfficials();
    },

    seenDetailPaymentStatus() {
      this.seen = this.formInput.payment_status;
    },

    titleFromSubOrganizationalUnit(item) {
      if (item == "Satker" || item == "Non-Satker") {
        this.seenComboboxWorkUnit = 1;
      } else {
        this.seenComboboxWorkUnit = 0;
      }

      this.title_select = item;
      this.itemSubOrganizationalUnit = item;
    },

    itemWorkUnits(item) {
      this.itemWorkUnitId = item;
    },

    formatNumber
  }
};
</script>
